/**
 * @file 文档菜单hook
 * @author FengGuang(fengguang01@baidu.com)
 */
import {IDocumentMenuItem} from '../type';
import innerText from '../../../../../utils/inner-text';
import {getNextElementSibling} from '../../../../../utils/get-sibling';


// 递归遍历 menu 树，抽取出 menu item 数据。
// 同时把选中的 menuItem 添加到 selectedKeys 里
const getMenuItemFromDomList = (
    theMenuDom: NodeListOf<Element>,
    deepLimit: number = -1,
    deep: number = 0
): { menuList: IDocumentMenuItem[], selectedKeys: string[] } => {
    if (!theMenuDom) {
        return {
            menuList: [],
            selectedKeys: []
        };
    }
    const selectedKeys: string[] = [];
    const theMenuList: IDocumentMenuItem[] = [];

    theMenuDom.forEach(tree1Dom => {
        const titleAItem: HTMLAnchorElement | null = tree1Dom.querySelector(':scope > a');
        const title = innerText(titleAItem);
        const href = titleAItem && titleAItem.href ? titleAItem.href : '';
        const link = href.replace(window.location.origin, '').replace(/#$/, '');
        const key = `$${deep}${title}${link}`;

        const tree1Obj: IDocumentMenuItem = {
            key,
            title,
            link,
            children: []
        };

        if (tree1Dom.matches('.current')) {
            selectedKeys.push(tree1Obj.key);
        }
        const {
            menuList: childrenMenuList,
            selectedKeys: childrenSelectedKeys
        } = (deepLimit <= -1 || deepLimit > deep)
            ? getMenuItemFromDomList(
                tree1Dom.querySelectorAll(':scope > ul > li'),
                deepLimit,
                deep + 1
            )
            : {
                menuList: [],
                selectedKeys: []
            };

        tree1Obj.children = childrenMenuList;

        theMenuList.push(tree1Obj);
        childrenSelectedKeys.forEach((s) => {
            selectedKeys.push(s);
        });
    });
    return {
        menuList: theMenuList,
        selectedKeys: selectedKeys
    };
};


const getMenuItemFromDom = (
    theMenuDom?: HTMLElement,
    deep: number = 0
): { menuList: IDocumentMenuItem[], selectedKeys: string[] } => {
    if (!theMenuDom) {
        return {
            menuList: [],
            selectedKeys: []
        };
    }

    let menuList: IDocumentMenuItem[] = [];
    let selectedKeys: string[] = [];

    const captionList = theMenuDom.querySelectorAll<HTMLElement>('.wy-menu > p.caption');
    captionList.forEach((pitem) => {
        const titleAItem = pitem.querySelector('span') as HTMLAnchorElement;
        const ulItem = getNextElementSibling(pitem, 'ul');
        const ulAItem = ulItem?.querySelector<HTMLAnchorElement>(':scope > li > a');

        const title = innerText(titleAItem);
        const href = titleAItem?.href ?? ulAItem?.href ?? '';
        const link = href.replace(window.location.origin, '').replace(/#$/, '');
        const key = `$${deep}${title}${link}`;

        if (ulItem?.matches('.current')) {
            selectedKeys.push(key);
        }

        const subRes = ulItem
            ? getMenuItemFromDomList(
                ulItem.querySelectorAll(':scope > li'),
                -1,
                deep
            )
            : undefined;

        let subMenuList = subRes?.menuList ?? [];

        let subSelectedKeys = subRes?.selectedKeys ?? [];

        const tree1Obj: IDocumentMenuItem = {
            key,
            title,
            link,
            children: subMenuList
        };
        menuList.push(tree1Obj);
        selectedKeys = [...selectedKeys, ...subSelectedKeys];
    });

    return {
        menuList,
        selectedKeys
    };
};

export default getMenuItemFromDom;
