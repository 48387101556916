import { ILiteNews } from './../../type';
import './style.less';

const LiteNews: React.FC<ILiteNews> = props => {
    const {
        title,
        desc,
        list
    } = props;

    return <div className='lite-page-news'>
        <div className='lite-page-news-title'>{ title }</div>
        <div className='lite-page-news-desc'>{ desc }</div>
        <div className='lite-page-news-card-wrapper'>
            {
                list.map(item => <div className='lite-page-news-desc-card' key={item.desc} onClick={() => {window.open(item?.btnLink)}}>
                    <img className='lite-page-news-desc-card-image' src={item.imageUrl} alt={item.desc} />
                    <div className='lite-page-news-desc-card-desc'>{ item.desc }</div>
                </div>)
            }
        </div>
    </div>;
};


export default LiteNews;