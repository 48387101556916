import { ILiteTask } from './../../type';
import './style.less';

const LiteTask: React.FC<ILiteTask> = props => {
    const {
        title,
        taskList
    } = props;

    return <div className='lite-page-task'>
        <div className='lite-page-task-title'>{ title }</div>
        <div className='lite-page-task-card-content'>
            {
                taskList.map(item => <div className='lite-page-task-content-card' key={item.title} onClick={() => {window.open(item?.btnLink)}}>
                    <img className='lite-page-task-content-card-image' src={item.imageUrl} alt={item.title} />
                    <div className='lite-page-task-content-card-collection'>
                        <div className='lite-page-task-content-card-collection-title'>{ item.title }</div>
                        <div className='lite-page-task-content-card-collection-desc'>{ item.desc }</div>
                        <div className='lite-page-task-content-card-collection-btn'>{ item.btnWord }</div>
                    </div>
                    
                </div>)
            }
        </div>
    </div>;
};


export default LiteTask;