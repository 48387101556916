/**
 * @file 获取文档正文
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useState} from 'react';
import {getSsrHostUrl} from '../../../../config/config';


// 从dom元素中获取文章正文内容
export const getDocArticleFromHTML = (documentDom?: HTMLElement | string) => {
    if (!documentDom) {
        return;
    }

    const docDom = (() => {
        if (typeof (documentDom) === 'string') {
            const frag = document.createElement('html');
            frag.innerHTML = documentDom;
            return frag;
        }
        return documentDom;
    })();

    // 正文的 dom
    const articleDom: HTMLElement | null = docDom.querySelector('[role="main"].document');

    if (!articleDom) {
        return;
    }

    // 处理正文中所有带id属性的元素，因为有可能成为锚点
    const anchorDomList = articleDom.querySelectorAll(':scope *[id]');
    anchorDomList.forEach(anchorDom => {
        const newAnchor = document.createElement('a');
        newAnchor.className = 'hide-anchor';
        newAnchor.name = anchorDom.id;
        newAnchor.id = anchorDom.id;
        if (anchorDom.childNodes[0]) {
            anchorDom.insertBefore(newAnchor, anchorDom.childNodes[0]);
        }
        else {
            anchorDom.appendChild(newAnchor);
        }
        anchorDom.removeAttribute('id');
    });

    // 给代码块提前添加 hljs
    articleDom.querySelectorAll<HTMLElement>(':scope .highlight').forEach((codeDom) => {
        codeDom.className = `${codeDom.className} hljs`;
    });

    // 添加别名相关dom
    // 事件在组件里绑定，hook里只生成dom
    articleDom.querySelectorAll<HTMLDivElement>(':scope .paddle_doc_api_alias_tags').forEach((aliasDom) => {
        const labelDom = document.createElement('div');
        labelDom.className = 'paddle-doc-alias-tags-label';
        const iconDom = document.createElement('span');
        iconDom.className = 'paddle-doc-alias-tags-label-icon';
        labelDom.appendChild(iconDom);
        labelDom.appendChild(document.createTextNode('查看属性与别名'));
        aliasDom.parentElement?.insertBefore(labelDom, aliasDom);
    });

    // 找到所有 img 标签，替换url
    articleDom.querySelectorAll<HTMLImageElement>(':scope img').forEach((imgDom) => {
        imgDom.src = getSsrHostUrl(imgDom.src);
    });

    return articleDom.outerHTML;
};


const useDocArticle = () => {
    const [article, setArticle] = useState<string>('');
    return {
        article,
        setArticle
    };
};

export default useDocArticle;
