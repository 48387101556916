import usePadlleLitePage from './LitePageHook';
import LiteBanner from './components/liteBanner/LiteBanner';
import LiteProcess from './components/liteProcess/LiteProcess';
import LiteArc from './components/liteArc/LiteArc';
import LiteTask from './components/liteTask/LiteTask';
import LiteJoin from './components/liteJoin/LiteJoin';
import LiteCooperate from './components/liteCooperate/LiteCooperate';
import LiteNews from './components/liteNews/LiteNews';

import './style.less';

const LitePage: React.FC = () => {
    const {
        liteBanner,
        liteProcess,
        liteArc,
        liteTask,
        liteJoin,
        liteCooperate,
        liteNews,
        liteArcHover
    } = usePadlleLitePage();

    return <div className='lite-page-wrapper'>
        <LiteBanner {...liteBanner} />
        <LiteProcess {...liteProcess} />
        <LiteArc {...liteArc} {...liteArcHover} />
        <LiteTask {...liteTask} />
        <LiteJoin {...liteJoin} />
        <LiteCooperate {...liteCooperate} />
        <LiteNews {...liteNews} />
    </div>;
};


export default LitePage;