/**
 * @file 导航栏的api
 * @author FengGuang(fengguang01@baidu.com)
 */

import request from 'superagent';

import baseRequest2 from '../../api/baseApi2';
import {getApiHostUrl} from '../../config/config';


/**
 * 获取搜索结果
 *
 * @param {string} kw 搜索的关键字
 * @return {Object} promise对象
 */
interface IDocInfoProps {
    language?: 'zh' | 'en';
    version?: string;
}

export const fetchGetHeaderSearchSug = (kw: string, docInfo: IDocInfoProps): Promise<request.Response> => {
    const theDocInfo: IDocInfoProps = {
        ...docInfo,
        ...(window as any).docInfo
    };
    const windowDocInfo = (window as any).docInfo || {};
    if ('lang' in windowDocInfo) {
        theDocInfo.language = windowDocInfo.lang;
    }
    return baseRequest2(
        request.get(getApiHostUrl('/searchapi'))
            .query({
                q: kw,
                language: theDocInfo.language,
                version: theDocInfo.version
            })
    );
};
