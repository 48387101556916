/**
 * @file 文档正文
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, {forwardRef} from 'react';
import classNames from 'classnames';

import {DocumentArticleProps} from './type';


const DocArticle = forwardRef<HTMLDivElement, DocumentArticleProps>((props, ref) => {
    return (
        <div
            ref={ref}
            className={classNames(
                'paddle-doc-page-content',
                'markdown-body'
            )}
            {...{dangerouslySetInnerHTML: {__html: props.article}}}
        >
        </div>
    );
});

export default DocArticle;
