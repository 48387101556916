/**
 * @file 遍历菜单选项并将他们平铺成数组
 * @author FengGuang(fengguang01@baidu.com)
 */

import {IDocumentMenuItem} from '../../../type';


// 遍历菜单选项并用map记录父元素
const getMenuMapByChildKey = (
    theMenuList: IDocumentMenuItem[],
    parentMap: Map<string, IDocumentMenuItem> = new Map<string, IDocumentMenuItem>(),
    parentItem?: IDocumentMenuItem
) => {
    theMenuList.forEach(menuItem => {
        if (parentItem) {
            parentMap.set(menuItem.key, parentItem);
        }
        getMenuMapByChildKey(menuItem.children, parentMap, menuItem);
    });
    return parentMap;
};

export default getMenuMapByChildKey;
