/**
 * @file 文档菜单hook
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useState} from 'react';
import {useLatest} from 'react-use';

import useDocTopMenu, {getTopMenuList} from './components/doc-top-menu/useDocTopMenu';
import useDocRightMenu, {getRightMenuList} from './components/doc-right-menu/useDocRightMenu';
import useDocLeftMenu, {getLeftMenuList} from './components/doc-left-menu/useDocLeftMenu';

import {IDocumentMenuItem} from './type';


const useDocMenu = (isPaddleInference: boolean) => {
    const [menuList, setMenuList] = useState<IDocumentMenuItem[]>([]);
    const docTopMenuHook = useDocTopMenu();
    const docRightMenuHook = useDocRightMenu();
    const docLeftMenuHook = useDocLeftMenu();

    const {
        setMenuList: setTopDocMenuList,
        setSelectedKeys: setTopDocMenuSelectedKeys
    } = docTopMenuHook;
    const {
        setMenuList: setLeftDocMenuList,
        setSelectedKeys: setLeftDocMenuSelectedKeys
    } = docLeftMenuHook;
    const {
        setMenuList: setRightDocMenuList,
        setSelectedKeys: setRightDocMenuSelectedKeys
    } = docRightMenuHook;

    const updateMenuList = useCallback(
        (
            menuList: IDocumentMenuItem[],
            selectedKeys?: string[]
        ) => {
            setMenuList(menuList);

            if (selectedKeys) {
                setTopDocMenuSelectedKeys(selectedKeys);
                setLeftDocMenuSelectedKeys(selectedKeys);
                setRightDocMenuSelectedKeys(selectedKeys);
            }
            setTopDocMenuList(getTopMenuList(menuList));
            setLeftDocMenuList(getLeftMenuList(menuList, selectedKeys || [], isPaddleInference));
            setRightDocMenuList(getRightMenuList(menuList, selectedKeys || []));
        },
        [
            setMenuList,
            setTopDocMenuList,
            setTopDocMenuSelectedKeys,
            setLeftDocMenuList,
            setLeftDocMenuSelectedKeys,
            setRightDocMenuList,
            setRightDocMenuSelectedKeys
        ]
    );

    const menuListRef = useLatest(menuList);
    const onTopMenuChange = useCallback((keys: string[]) => {
        const leftMenuList = getLeftMenuList(menuListRef.current, keys || []);
        setTopDocMenuSelectedKeys(keys);
        setLeftDocMenuSelectedKeys(keys);
        setLeftDocMenuList(leftMenuList);
    }, [
        menuListRef,
        setLeftDocMenuList,
        setTopDocMenuSelectedKeys,
        setLeftDocMenuSelectedKeys
    ]);

    return {
        updateMenuList,
        onTopMenuChange,

        menu: {
            menuList,
            setMenuList
        },
        top: docTopMenuHook,
        right: docRightMenuHook,
        left: docLeftMenuHook
    };
};

export default useDocMenu;
