/**
 * @file 页面滚动时选中对应锚点
 * @author FengGuang(fengguang01@baidu.com)
 */
import {DependencyList, RefObject, useEffect, useMemo, useRef} from 'react';
import {useLatest} from 'react-use';

import throttle from 'lodash/throttle';
import {IDocumentMenuItem} from '../../type';
import getMenuFlat from './tools/getMenuFlat';
import getMenuMapByChildKey from './tools/getMenuMapByChildKey';
import getParentsKeyList from './tools/getParentsKeyList';


type IScrollEffectParam = {
    key: string;
    keys: string[];
    item: IDocumentMenuItem;
    parentKeys: string[];
};
type IScrollEffect = (param: IScrollEffectParam) => (void | undefined);

type IAnchorScrollHighlightParams = [
    RefObject<HTMLElement>,
    IDocumentMenuItem[]
];


const useAnchorScrollHighlight = (
    effect: IScrollEffect,
    params: IAnchorScrollHighlightParams,
    deps?: DependencyList
) => {
    const [docArticleRef, menuList] = params;
    // 锚点name的列表
    // 锚点name的map
    const [
        menuNameSet,
        menuListMapByName,
        menuListMapByChildKey
    ] = useMemo(() => {
        const menuFlat = getMenuFlat(menuList);
        const listMapByName = new Map<string, IDocumentMenuItem>();

        menuFlat.forEach(menuItem => {
            const key = (menuItem.link || '').replace(/[^#]*#/, '');
            listMapByName.set(key, menuItem);
        });

        const listMapByKey = getMenuMapByChildKey(menuList);

        return [
            new Set<string>(listMapByName.keys()),
            listMapByName,
            listMapByKey
        ];
    }, [menuList]);

    // 要被遍历的锚点dom
    const archorDomList = useMemo(() => {
        if (!docArticleRef.current || menuList.length === 0) {
            return [];
        }
        const documentDom = docArticleRef.current;
        // 获取文档正文中所有带id属性和a[name]属性的节点，这些节点有可能被锚点锚定
        const anchoDomListNotSort = Array.from(
            new Set([
                ...Array.from(documentDom.querySelectorAll<HTMLElement>(':scope [id]')),
                ...Array.from(documentDom.querySelectorAll<HTMLElement>(':scope a[name]'))
            ])
        );
        // 排序
        const anchoDomListSort = anchoDomListNotSort
            .map(anchoDom => ({dom: anchoDom, top: anchoDom.getBoundingClientRect().top}));
        anchoDomListSort.sort((a, b) => a.top - b.top);
        const anchoDomList = anchoDomListSort.map(item => item.dom);

        // 取 dom 和锚点链接的交集，这些 dom 作为最后要监听滚动事件时计算位置的 dom
        return anchoDomList.filter(domItem => {
            const anchorDomName = domItem.id || domItem.getAttribute('name') || '';
            return menuNameSet.has(anchorDomName);
        });
    }, [docArticleRef, menuList, menuNameSet]);

    const lastKeyRef = useRef<string>('');
    const effectRef = useLatest(effect);

    // 监听锚点dom的位置
    useEffect(() => {
        // 使用 throttle 减轻计算压力
        const scrollHandle = throttle(() => requestAnimationFrame(() => {
            if (archorDomList.length === 0) {
                return;
            }
            let targetArchorDom: HTMLElement | undefined;
            // top 位置在屏幕中线和顶部之间的锚点 dom 被认为是正在展示的锚点
            const screenCenterLine = window.innerHeight / 3;
            const offset = 10;

            // 获取锚点 dom 的 top 值
            const archorDomTopList = archorDomList.map(archorDom => archorDom.getBoundingClientRect().top + offset);
            // 找到最接近屏幕顶端的锚点
            const archorDomBellowTop = archorDomTopList.filter(top => top > 0);
            if (archorDomBellowTop.length > 0) {
                const targetTop = archorDomBellowTop[0];
                const targetIndex = archorDomTopList.indexOf(targetTop);
                // 如果这个锚点在屏幕上半部分，就认为它是当前展示的锚点
                if (targetTop < screenCenterLine) {
                    targetArchorDom = archorDomList[targetIndex];
                }
                // 如果这个锚点在屏幕下半部分则认为他之前的那个锚点是当前正在展示的
                else {
                    targetArchorDom = archorDomList[targetIndex - 1] || archorDomList[targetIndex];
                }
            }
            // 屏幕里没有正在展示的锚点，直接取列表末尾的
            else {
                targetArchorDom = archorDomList.slice(-1)[0];
            }

            if (targetArchorDom) {
                const targetLink = targetArchorDom.getAttribute('name') || '';
                const targetMenuItem = menuListMapByName.get(targetLink);
                if (targetMenuItem) {
                    if (lastKeyRef.current !== targetMenuItem.key) {
                        lastKeyRef.current = targetMenuItem.key;
                        effectRef.current({
                            key: targetMenuItem.key,
                            keys: [targetMenuItem.key],
                            item: targetMenuItem,
                            parentKeys: getParentsKeyList(menuList, targetMenuItem.key, menuListMapByChildKey)
                        });
                    }
                }
            }
        }), 100);
        window.addEventListener('scroll', scrollHandle);
        window.addEventListener('resize', scrollHandle);
        return () => {
            window.removeEventListener('scroll', scrollHandle);
            window.removeEventListener('resize', scrollHandle);
        };
    }, [
        effectRef,
        menuList,
        menuListMapByName,
        menuListMapByChildKey,
        archorDomList
    ]);

    return;
};

export default useAnchorScrollHighlight;
