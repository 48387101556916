/**
 * @file 原始文档url
 * @author FengGuang(fengguang01@baidu.com)
 */
const isSandBox = window.location.hostname.startsWith('sandbox--')
    && window.location.hostname.includes('.paddlepaddle.org.cn');


export const ssrUrl = (() => {
    if (isSandBox) {
        return `ssr-${window.location.hostname}`;
    }
    return `ssr--${window.location.hostname}`;
})();

export const apiUrl = (() => {
    if (isSandBox) {
        return 'sandbox.paddlepaddle.org.cn';
    }
    return 'www.paddlepaddle.org.cn';
})();

export const isPaddleLite = window.location.hostname.includes('paddlelite.paddlepaddle.org.cn');

export const isPaddleInference = window.location.hostname.includes('paddleinference.paddlepaddle.org.cn');

export const getSsrHostUrl = (url: string) => {
    const theUrl = new URL(url, window.location.href);
    if (window.location.hostname === theUrl.hostname) {
        if (theUrl.hostname === 'localhost') {
            theUrl.searchParams.set('raw', '' + BUILDHASH);
            return theUrl.href;
        }
        theUrl.port = '';
        theUrl.hostname = ssrUrl;
        theUrl.searchParams.set('raw', '' + BUILDHASH);
        return theUrl.href;
    }

    return url;
};

export const getApiHostUrl = (url: string) => {
    const theUrl = new URL(url, window.location.href);
    if (window.location.hostname === theUrl.hostname) {
        if (theUrl.hostname === 'localhost') {
            theUrl.searchParams.set('raw', '' + BUILDHASH);
            return theUrl.href;
        }
        theUrl.hostname = apiUrl;
        return theUrl.href;
    }

    return url;
};

export const getHostUrl = (url: string) => {
    const theUrl = new URL(url, window.location.href);
    if (window.location.hostname === theUrl.hostname) {
        theUrl.hostname = apiUrl;
        return theUrl.href;
    }

    return url;
};

const config = {
    ssrUrl,
    getSsrHostUrl,
    apiUrl
};
export default config;
