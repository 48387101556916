/**
 * @file 文档页
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, { FC, useState } from 'react';
import ReactStickyPolyfill from '../../components/react-sticky-polyfill/ReactStickyPolyfill';

import useDocument from './documentHooks';
import Header from '../../components/header/HeaderWithStore';
import Footer from '../../components/footer/Footer';
import LiteDoc from './PaddleLite';
import InferenceDocument from './PaddleInference';
import LiteDemo from './components/lite-demo/LiteDemo';
import LitePage from './components/lite-page/LitePage';

import { useHistory, useLocation } from 'react-router-dom';
import VersionSelect from './components/version-select';
import { Tabs } from 'antd';

import './style.less';


const Document: FC = () => {
    const {
        isPaddleInference,
        versionSelectHook
    } = useDocument();

    const { TabPane } = Tabs;
    const history = useHistory();
    const location = useLocation();
    const [activeKey, setActiveKey] = useState<string>(() => {
        const path = location.pathname;
        const hash = location.hash;
        if (path === '/') {
            if (hash === '#demo') {
                return 'demo';
            }
            return 'paddleLite';
        }
        else if (path.includes('/introduction/faq.html')) {
            return 'faq';
        }
        else if (path.includes('api_reference')) {
            return 'apiDoc';
        }
        else {
            return 'guide';
        }
    });

    const initTabsParams = (currentKey: string) => {
        const version = versionSelectHook.value ? versionSelectHook.value : 'v2.10';
        setActiveKey(currentKey);
        if (currentKey === 'paddleLite') {
            history.push('/');
        }
        else if (currentKey === 'demo') {
            history.push('/#demo');
        }
        else if (currentKey === 'apiDoc') {
            history.push(`/${version}/api_reference/cxx_api_doc.html`);
        }
        else if (currentKey === 'faq') {
            history.push(`/${version}/quick_start/faq.html`)
        }
        else if (currentKey === 'guide') {
            history.push(`/${version}/guide/introduction.html`)
        }
    };

    return (
        isPaddleInference
        ? <InferenceDocument />
        :   <div className="page paddle-doc-page">
            <Header />
            <ReactStickyPolyfill>
                <div className="paddle-doc-page-top">
                    <div className="paddle-doc-page-top-menu">
                        {versionSelectHook.options.length > 0
                            && (
                                <VersionSelect
                                    {...versionSelectHook}
                                />
                            )
                        }
                    </div>
                    <div className="paddle-doc-page-top-inner" />
                </div>
            </ReactStickyPolyfill>
            
            <Tabs activeKey={activeKey} onChange={initTabsParams}>

                <TabPane tab="关于Paddle Lite" key="paddleLite">
                    <LitePage />
                </TabPane>


                <TabPane tab="使用指南" key="guide">
                    <LiteDoc flag='guide' />
                </TabPane>
                
                <TabPane tab="应用实践" key="demo">
                    <LiteDemo />
                </TabPane>

                <TabPane tab="API文档" key="apiDoc">
                    <LiteDoc flag='apiDoc' />
                </TabPane>

                <TabPane tab="常见问题与解答" key="faq">
                    <LiteDoc flag='faq' />
                </TabPane>

                
            </Tabs>
            
            <Footer />
        </div>
    );
};

export default Document;
