import { useState } from 'react';
import { useMount } from 'react-use';
import { getCmsDataInfo } from './../../../../api/paddleCmsData';
import { IpaddleLiteDemo } from './type';

const usePadlleLiteDemo = () => {
    const [liteDemo, setLiteDemo] = useState<IpaddleLiteDemo>({
        title: '',
        desc: '',
        classifyList: [],
        taskList: []
    });

    useMount(() => {
        (async () => {
            const res = await getCmsDataInfo({requestUrl: '/paddleLiteDemo'})
                .catch((err) => {
                    // ignore
                });
            if (res) {
                const data = res.body.result?.pageData || {};
                data.paddleLiteDemo && setLiteDemo(data.paddleLiteDemo);
            }
        })()
    });

    return {
        liteDemo
    }
};

export default usePadlleLiteDemo;