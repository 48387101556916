/**
 * @file 顶部导航栏的数据
 * @author FengGuang(fengguang01@baidu.com)
 */
import UrlPattern from 'url-pattern';

export interface IMenuDataTagsItem {
    color: 'red' | 'green' | 'blue';
    text: string;
}

export interface IMenuDataItem {
    key: string;
    title: string;
    url?: string;
    urlPattern?: string | string[];
    urlPatternExclude?: string | string[];
    tags?: IMenuDataTagsItem[];
    target?: string | boolean;
    children?: IMenuDataItem[];
}

// 根据 url 设置唯一key
const setKeyFromObj = (item: IMenuDataItem[]): IMenuDataItem[] => {
    return item.map((subItem, index) => {
        if (!subItem.key && subItem.url) {
            subItem.key = subItem.url;
        }
        else if (!subItem.key && subItem.title) {
            subItem.key = `$${subItem.title}`;
        }
        else if (!subItem.key) {
            subItem.key = `$${index}`;
        }
        if (subItem.children) {
            subItem.children = setKeyFromObj(subItem.children);
        }
        return subItem;
    });
};

const menuData: IMenuDataItem[] = setKeyFromObj([
    {
        key: '',
        title: '安装',
        url: 'https://www.paddlepaddle.org.cn/install/quick',
        urlPattern: '/install/**',
        target: true
    },
    {
        key: '',
        title: '教程',
        url: 'https://www.paddlepaddle.org.cn/tutorials',
        urlPattern: [
            '/tutorials',
            '/tutorials/**'
        ],
        target: true
    },
    {
        key: '',
        title: '文档',
        urlPattern: [
            '/**'
        ],
        children: [
            {
                key: '',
                title: 'PaddlePaddle',
                url: 'https://paddlepaddle.org.cn/documentation/docs/zh/guides/index_cn.html',
                urlPattern: '/documentation/docs/**'
            },
            {
                key: '',
                title: 'Paddle Lite',
                url: 'https://paddlelite.paddlepaddle.org.cn/introduction/tech_highlights.html',
                urlPattern: '/lite/**'
            },
            {
                key: '',
                title: 'Paddle Inference',
                url: 'https://paddleinference.paddlepaddle.org.cn/product_introduction/inference_intro.html',
                urlPattern: '/inference/**'
            }
        ]
    },
    {
        key: '',
        title: '模型库',
        url: 'https://www.paddlepaddle.org.cn/modelbase',
        urlPattern: [
            '/modelbase',
            '/modelbasedetail',
            '/modelbasedetail/**'
        ],
        target: true
    },
    {
        key: '',
        title: '产品全景',
        url: 'https://www.paddlepaddle.org.cn/overview',
        urlPattern: '/overview',
        target: true,
        children: [
            {
                key: '',
                title: '工具组件',
                children: [
                    {
                        key: '',
                        title: 'PaddleHub',
                        url: 'https://www.paddlepaddle.org.cn/hub',
                        tags: [{color: 'red', text: '热门'}],
                        target: true
                    },
                    {
                        key: '',
                        title: 'PaddleX',
                        url: 'https://www.paddlepaddle.org.cn/paddle/paddleX',
                        tags: [{color: 'green', text: '新品'}],
                        target: true
                    },
                    {
                        key: '',
                        title: 'VisualDL',
                        url: 'https://www.paddlepaddle.org.cn/paddle/visualdl',
                        target: true
                    },
                    {
                        key: '',
                        title: 'PaddleCloud',
                        url: 'https://www.paddlepaddle.org.cn/paddle/paddlecloud',
                        tags: [{color: 'blue', text: '邀测'}],
                        target: true
                    },
                    {
                        key: '',
                        title: 'AutoDL',
                        url: 'https://github.com/PaddlePaddle/AutoDL',
                        target: true
                    },
                    {
                        key: '',
                        title: 'PARL',
                        url: 'https://github.com/PaddlePaddle/PARL',
                        target: true
                    },
                    {
                        key: '',
                        title: 'PALM',
                        url: 'https://github.com/PaddlePaddle/PALM',
                        target: true
                    },
                    {
                        key: '',
                        title: 'PaddleFL',
                        url: 'https://github.com/PaddlePaddle/PaddleFL',
                        target: true
                    },
                    {
                        key: '',
                        title: 'PGL',
                        url: 'https://github.com/PaddlePaddle/PGL',
                        target: true
                    },
                    {
                        key: '',
                        title: 'Paddle Quantum',
                        url: 'https://github.com/PaddlePaddle/Quantum',
                        tags: [{color: 'green', text: '新品'}],
                        target: true
                    }
                ]
            },
            {
                key: '',
                title: '端到端开发套件',
                children: [
                    {
                        key: '',
                        title: 'ERNIE',
                        url: 'https://www.paddlepaddle.org.cn/paddle/ernie',
                        target: true
                    },
                    {
                        key: '',
                        title: 'PaddleClas',
                        url: 'https://github.com/PaddlePaddle/PaddleClas',
                        tags: [{color: 'green', text: '新品'}],
                        target: true
                    },
                    {
                        key: '',
                        title: 'PaddleDetection',
                        url: 'https://github.com/PaddlePaddle/PaddleDetection',
                        target: true
                    },
                    {
                        key: '',
                        title: 'PaddleSeg',
                        url: 'https://github.com/PaddlePaddle/PaddleSeg',
                        target: true
                    },
                    {
                        key: '',
                        title: 'PaddleOCR',
                        url: 'https://github.com/PaddlePaddle/PaddleOCR',
                        target: true
                    },
                    {
                        key: '',
                        title: 'PLSC',
                        url: 'https://github.com/PaddlePaddle/PLSC',
                        target: true
                    },
                    {
                        key: '',
                        title: 'ElasticCTR',
                        url: 'https://github.com/PaddlePaddle/ElasticCTR',
                        target: true
                    },
                    {
                        key: '',
                        title: 'Parakeet',
                        url: 'https://github.com/PaddlePaddle/Parakeet',
                        tags: [{color: 'green', text: '新品'}],
                        target: true
                    },
                    {
                        key: '',
                        title: 'PaddleHelix',
                        url: 'https://www.paddlepaddle.org.cn/paddle/paddlehelix',
                        tags: [{color: 'green', text: '新品'}],
                        target: true
                    }
                ]
            },
            {
                key: '',
                title: '基础模型库',
                children: [
                    {
                        key: '',
                        title: 'PaddleCV',
                        url: 'https://www.paddlepaddle.org.cn/paddle/paddlecv',
                        tags: [{color: 'red', text: '热门'}],
                        target: true
                    },
                    {
                        key: '',
                        title: 'PaddleNLP',
                        url: 'https://github.com/PaddlePaddle/PaddleNLP',
                        target: true
                    },
                    {
                        key: '',
                        title: 'PaddleRec',
                        url: 'https://github.com/PaddlePaddle/PaddleRec',
                        target: true
                    },
                    {
                        key: '',
                        title: 'PaddleSpeech',
                        url: 'https://github.com/PaddlePaddle/models#PaddleSpeech',
                        target: true
                    }
                ]
            },
            {
                key: '',
                title: '预测部署',
                children: [
                    {
                        key: '',
                        title: 'PaddleSlim',
                        url: 'https://github.com/PaddlePaddle/PaddleSlim',
                        target: true
                    },
                    {
                        key: '',
                        title: 'Paddle Inference',
                        url: 'https://github.com/PaddlePaddle/Paddle-Inference-Demo',
                        target: true
                    },
                    {
                        key: '',
                        title: 'Paddle Serving',
                        url: 'https://github.com/PaddlePaddle/Serving',
                        target: true
                    },
                    {
                        key: '',
                        title: 'Paddle Lite',
                        url: 'https://www.paddlepaddle.org.cn/paddle/paddlelite',
                        tags: [{color: 'red', text: '热门'}],
                        target: true
                    },
                    {
                        key: '',
                        title: 'Paddle.js',
                        url: 'https://www.paddlepaddle.org.cn/paddle/paddlejs',
                        tags: [{color: 'blue', text: '邀测'}],
                        target: true
                    }
                ]
            },
            {
                key: '',
                title: '开发训练',
                children: [
                    {
                        key: '',
                        title: 'Paddle核心框架',
                        url: 'https://github.com/paddlepaddle/paddle',
                        target: true
                    },
                    {
                        key: '',
                        title: 'FleetAPI',
                        url: 'https://github.com/PaddlePaddle/Fleet',
                        target: true
                    }
                ]
            },
            {
                key: '',
                title: '',
                children: [
                    {
                        key: '',
                        title: 'EasyDL零门槛AI开发平台',
                        url: 'https://ai.baidu.com/easydl/',
                        target: true
                    },
                    {
                        key: '',
                        title: 'BML全功能AI开发平台',
                        url: 'https://cloud.baidu.com/product/bml.html',
                        target: true
                    },
                    {
                        key: '',
                        title: 'AI studio 学习与实训社区',
                        url: 'https://aistudio.baidu.com/',
                        target: true
                    }
                ]
            }
        ]
    },
    {
        key: '',
        title: '应用案例',
        url: 'https://www.paddlepaddle.org.cn/customercase',
        urlPattern: '/customercase',
        target: true
    },
    {
        key: '',
        title: '直播日历',
        url: 'https://www.paddlepaddle.org.cn/live',
        urlPattern: '/live',
        target: true
    },
    {
        key: '',
        title: '开发者社区',
        children: [
            {
                key: '',
                title: '飞桨开发者技术专家（PPDE）',
                url: 'https://www.paddlepaddle.org.cn/ppde',
                target: true
            },
            {
                key: '',
                title: '飞桨领航团',
                url: 'https://www.paddlepaddle.org.cn/ppdenavigategroup',
                target: true
            },
            {
                key: '',
                title: '深度学习工程师认证',
                url: 'https://aistudio.baidu.com/aistudio/certification',
                target: true
            },
            {
                key: '',
                title: '技术论坛',
                url: 'https://ai.baidu.com/forum/topic/list/168',
                target: true
            }
        ]
    }
]);


export const menuRightData: IMenuDataItem[] = setKeyFromObj([
    {
        key: '',
        title: '资讯',
        url: 'https://www.paddlepaddle.org.cn/support/news',
        urlPattern: [
            '/support/news',
            '/support/news/**'
        ],
        target: true
    },
    {
        key: '',
        title: 'GitHub',
        url: 'https://github.com/paddlepaddle/paddle',
        target: true
    }
]);


// 遍历元素找到当前页面 url 匹配的选项
export const findMenu = (
    theMenuData: IMenuDataItem[],
    targetUrl: string = window.location.href.slice(window.location.origin.length)
): IMenuDataItem[] => {
    let resItem: IMenuDataItem[] = [];
    theMenuData.some((item) => {
        // 先匹配 urlPatternExclude 如果匹配上说明这条不是我们需要的记录
        let urlPatternExclude: string[] = [];
        if (Array.isArray(item.urlPatternExclude)) {
            urlPatternExclude = item.urlPatternExclude;
        }
        else if (typeof (item.urlPatternExclude) === 'string') {
            urlPatternExclude = [item.urlPatternExclude];
        }
        const patternExcludeMatch = urlPatternExclude.some(
            urlpatt => !!(new UrlPattern(urlpatt).match(targetUrl))
        );
        if (patternExcludeMatch) {
            return false;
        }

        // 再匹配 urlExclude，如果匹配上说明这是我们需要的记录
        let urlPattern: string[] = [];
        if (Array.isArray(item.urlPattern)) {
            urlPattern = item.urlPattern;
        }
        else if (typeof (item.urlPattern) === 'string') {
            urlPattern = [item.urlPattern];
        }

        const patternMatch = urlPattern.some(urlpatt => {
            if (new UrlPattern(urlpatt).match(targetUrl)) {
                resItem.push(item);
                return true;
            }
            return false;
        });
        if (patternMatch) {
            return true;
        }

        // 如果都没匹配到，则去递归遍历子节点
        if (item.children) {
            const subResItem = findMenu(item.children, targetUrl);
            if (subResItem.length) {
                resItem.push(item);
                subResItem.forEach(childItem => {
                    resItem.push(childItem);
                });
                return true;
            }
        }
        return false;
    });
    return resItem;
};

export default menuData;
