/**
 * @file 版本选择器
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, {FC} from 'react';
import {Select} from 'antd';
import classNames from 'classnames';
import {VersionSelectProps} from './types';
import {
    CaretDownOutlined
} from '@ant-design/icons';

import './style.less';

const VersionSelect: FC<VersionSelectProps> = (props) => {
    const {
        className
    } = props;

    return (
        <Select
            {...props}
            className={classNames(
                className,
                'paddle-version-select'
            )}
            dropdownMatchSelectWidth={false}
            dropdownClassName="paddle-version-select-drop"
            optionLabelProp="label"
            size="large"
            {...{suffixIcon: <CaretDownOutlined />}}
        />
    );
};

export default VersionSelect;
