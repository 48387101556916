/**
 * @file 从dom中获取title
 * @author FengGuang(fengguang01@baidu.com)
 */
const getTitleFromDom = (dom?: HTMLElement) => {
    const theDom = dom || document;
    if (theDom) {
        return theDom.getElementsByTagName('title')?.[0]?.innerHTML ?? '';
    }
    return '';
};

export default getTitleFromDom;
