/**
 * @file 文档主组件
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, {FC} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

import Document from './pages/docs/Document';

import './App.less';

const App: FC = () => {
    return (
        <Router>
            <Switch>
                <Route path="/">
                    <Document />
                </Route>
            </Switch>
        </Router>
    );
};

export default App;
