/**
 * @file 寻找兄弟元素
 * @author FengGuang(fengguang01@baidu.com)
 */

export const getPreviousElementSibling = (dom: HTMLElement, selector?: string) => {
    if (!selector) {
        return dom.previousElementSibling as HTMLElement;
    }
    let current = dom.previousElementSibling as HTMLElement | null;
    while (current) {
        if (current.matches(selector)) {
            break;
        }
        current = current.previousElementSibling as HTMLElement;
    }
    return current;
};

export const getNextElementSibling = (dom: HTMLElement, selector?: string) => {
    if (!selector) {
        return dom.nextElementSibling as HTMLElement;
    }
    let current = dom.nextElementSibling as HTMLElement | null;
    while (current) {
        if (current.matches(selector)) {
            break;
        }
        current = current.nextElementSibling as HTMLElement;
    }
    return current;
};
