/**
 * @file PaddlePaddle 主站页脚
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';

import footerData from './footerData';
import baiduweixinqr from './image/paddlepaddleQr.jpg';
import qqqr from './image/qqqr.png';

import './style.less';

const Footer: React.FC = React.memo(() => {
    return (
        <footer className="paddle-footer">
            <div className="paddle-footer-container">
                {footerData.map(group => (
                    <div key={group.groupName} className="paddle-footer-group">
                        <div className="paddle-footer-group-title">
                            {group.groupName}
                        </div>
                        <div className="paddle-footer-item-list">
                            {group.list.map(item => (
                                <div
                                    className="paddle-footer-item"
                                    key={`${group.groupName},${item.name}`}
                                >
                                    {item.url
                                        ? <a
                                            target={item.target}
                                            href={item.url}
                                            rel={item.target === '_blank' ? 'noopener noreferrer' : undefined}
                                        >
                                            {item.name}
                                        </a>
                                        : item.name}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                <div className="paddle-footer-qr-wrap">
                    <div className="paddle-footer-qr-item">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://qm.qq.com/cgi-bin/qm/qr?k=_XWkmFmva9AaKdGSuH9XonG-hrEkNidn&authKey=OXRrpF1WDLtGSOB4FnRO9utSkspES+mPjZ+HN8HzP/1EjExVdMC0isV7iNXeH/Wd"
                        >
                            <div className="paddle-footer-qr-context"
                                 style={{backgroundImage: `url(${qqqr})`}}>
                                <img className="paddle-footer-qr-context-img" alt="paddle weixin qr"
                                     src={qqqr} />
                            </div>
                            <div className="paddle-footer-qr-title">
                                <div>飞桨官方技术交流群</div>
                                <div className="paddle-footer-qr-subtitle">
                                    (QQ群号:703252161)
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="paddle-footer-qr-item">
                        <div className="paddle-footer-qr-context"
                             style={{backgroundImage: `url(${baiduweixinqr})`}}>
                            <img className="paddle-footer-qr-context-img" alt="paddle weixin qr"
                                 src={baiduweixinqr} />
                        </div>
                        <div className="paddle-footer-qr-title">
                            飞桨微信公众号
                        </div>
                    </div>
                </div>
            </div>
            <div className="paddle-footer-bottom">
                ©Copyright 2020, PaddlePaddle developers.
            </div>
        </footer>
    );
});

export default Footer;
