/**
 * @file 文档页页面顶部tab菜单
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useCallback} from 'react';
import {Menu} from 'antd';
import {MenuProps} from 'antd/es/menu';

import LinkA from '../../../../../../components/a/LinkA';
import {IDocumentMenuItem} from '../../type';

export interface IDocTopMenuProps {
    selectedKeys: string[];
    menuList: IDocumentMenuItem[];
    onClick?: (keys: string[]) => void;
}

const DocTopMenu: React.FC<IDocTopMenuProps> = props => {
    const {
        selectedKeys,
        menuList,
        onClick
    } = props;

    const handleOnClick = useCallback<Exclude<MenuProps['onClick'], undefined>>(
        (props) => {
            onClick && onClick(props.key ? [`${props.key || ''}`] : []);
        },
        [onClick]
    );

    return (
        <Menu
            mode="horizontal"
            className="paddle-doc-page-top-menu"
            selectedKeys={selectedKeys}
            onClick={handleOnClick}
        >
            {menuList.map(menuItem => (
                <Menu.Item key={menuItem.key}>
                    <LinkA to={menuItem.link}>
                        {menuItem.title}
                    </LinkA>
                </Menu.Item>
            ))}
        </Menu>
    );
};

export default DocTopMenu;
