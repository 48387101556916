/**
 * @file 遍历菜单选项并将他们平铺成数组
 * @author FengGuang(fengguang01@baidu.com)
 */

import {IDocumentMenuItem} from '../../../type';

const getMenuFlat = (theMenuList: IDocumentMenuItem[]) => {
    let resMenuArray: IDocumentMenuItem[] = [];
    theMenuList.forEach(menuItem => {
        resMenuArray.push(menuItem);
        resMenuArray = resMenuArray.concat(getMenuFlat(menuItem.children));
    });
    return resMenuArray;
};

export default getMenuFlat;
