import { useState } from 'react';
import { useMount } from 'react-use';
import { getCmsDataInfo } from './../../../../api/paddleCmsData';
import { 
    ILiteBanner,
    ILiteProcess,
    ILiteArc,
    ILiteTask,
    ILiteJoin,
    ILiteCooperate,
    ILiteNews,
    IArcHover
} from './type';

const usePadlleLitePage = () => {
    const [liteBanner, setLiteBanner] = useState<ILiteBanner>({
        title: '',
        desc: '',
        btnWord1: '',
        btnLink1: '',
        btnWord2: '',
        btnLink3: ''
    });

    const [liteProcess, setLiteProcess] = useState<ILiteProcess>({
        title: '',
        list: []
    });

    const [liteArc, setLiteArc] = useState<ILiteArc>({
        title: '',
        exeuate: {
            title: '',
            step1: {
                title: '',
                desc: ''
            },
            step2: {
                title: '',
                desc: ''
            },
            step3: {
                title: '',
                desc: ''
            },
            step4: {
                title: '',
                desc: ''
            }
        },
        analyze: {
            title: '',
            step1: {
                title: '',
                desc: ''
            },
            step2: {
                title: '',
                desc: ''
            }
        },
        mark: []
    });

    const [liteTask, setLiteTask] = useState<ILiteTask>({
        title: '',
        taskList: []
    });

    const [liteJoin, setLiteJoin] = useState<ILiteJoin>({
        title: '',
        desc: '',
        list: []
    });

    const [liteCooperate, setLiteCooperate] = useState<ILiteCooperate>({
        title: '',
        desc: '',
        list: []
    });

    const [liteNews, setLiteNews] = useState<ILiteNews>({
        title: '',
        desc: '',
        list: []
    });

    const [liteArcHover, setLiteArcHover] = useState<IArcHover>({
        operateAccess: [],
        subAccess: [],
        handwareAccess: [],
        loadAndCalc: [],
        fusionPass: []
    });

    useMount(() => {
        (async () => {
            const res = await getCmsDataInfo({requestUrl: '/paddleLitePage'})
                .catch((err) => {
                    // ignore
                });
            if (res) {
                const data = res.body.result?.pageData || {};
                data.banner && setLiteBanner(data.banner);
                data.paddleLiteProcess && setLiteProcess(data.paddleLiteProcess);
                data.paddleliteArc && setLiteArc(data.paddleliteArc);
                data.paddleLiteTask && setLiteTask(data.paddleLiteTask);
                data.paddleLiteJoin && setLiteJoin(data.paddleLiteJoin);
                data.paddleLiteCooperate && setLiteCooperate(data.paddleLiteCooperate);
                data.paddleLiteNews && setLiteNews(data.paddleLiteNews);
                data.paddleLiteArcHover && setLiteArcHover(data.paddleLiteArcHover);
            }
        })()
    });

    return {
        liteBanner,
        liteProcess,
        liteArc,
        liteTask,
        liteJoin,
        liteCooperate,
        liteNews,
        liteArcHover
    }
};

export default usePadlleLitePage;