/**
 * @file 文档菜单hook
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useState} from 'react';

import {IDocumentMenuItem} from '../../type';

export const getTopMenuList = (menuList: IDocumentMenuItem[]) => {
    return menuList.map<IDocumentMenuItem>((item) => ({
        ...item,
        children: []
    }));
};

const useDocTopMenu = () => {
    const [menuList, setMenuList] = useState<IDocumentMenuItem[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    return {
        menuList,
        selectedKeys,

        setMenuList,
        setSelectedKeys
    };
};

export default useDocTopMenu;
