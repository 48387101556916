/**
 * @file 文档页
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, { FC } from 'react';
import classNames from 'classnames';
import DocArticle from './components/doc-article/DocArticle';
import ReactStickyPolyfill from '../../components/react-sticky-polyfill/ReactStickyPolyfill';
import DocTopMenu from './components/doc-menu/components/doc-top-menu/DocTopMenu';
import useDocument from './documentHooks';
import Header from '../../components/header/HeaderWithStore';
import Footer from '../../components/footer/Footer';
import DocLeftMenu from './components/doc-menu/components/doc-left-menu/DocLeftMenu';
import DocRightMenu from './components/doc-menu/components/doc-right-menu/DocRightMenu';
import './style.less';
import VersionSelect from './components/version-select';
const InferenceDocument: FC = () => {
    const {
        leftMenuShow,
        toggleLeftMenuShow,
        docArticleRef,
        docArticleHook,
        docMenuHooks,
        versionSelectHook
    } = useDocument();
    return (
        <div className="page paddle-doc-page">
            <Header />
            <ReactStickyPolyfill>
                <div className="paddle-doc-page-top">
                    <div className="paddle-doc-page-top-menu">
                        {versionSelectHook.options.length > 0
                            && (
                                <VersionSelect
                                    {...versionSelectHook}
                                />
                            )
                        }
                    </div>
                    <div className="paddle-doc-page-top-inner">
                        <DocTopMenu
                            {...docMenuHooks.top}
                            onClick={docMenuHooks.onTopMenuChange}
                        />
                    </div>
                </div>
            </ReactStickyPolyfill>
            <div className={
                classNames(
                    'paddle-doc-page-container',
                    {
                        'paddle-doc-page-container-no-left':
                            docMenuHooks.left.menuList.length === 0,
                        'paddle-doc-page-container-no-right':
                            docMenuHooks.right.menuList.length === 0
                    }
                )}
            >
                <div
                    className={classNames(
                        'paddle-doc-page-main',
                        {
                            'paddle-doc-page-left-menu-show': leftMenuShow
                        }
                    )}
                >
                    <div className="paddle-doc-page-left-background" />
                    <div className="paddle-doc-page-left">
                        <ReactStickyPolyfill>
                            <div className="paddle-doc-page-left-menu-wrap">
                                <DocLeftMenu
                                    {...docMenuHooks.left}
                                />
                            </div>
                        </ReactStickyPolyfill>
                    </div>
                    <div className="paddle-doc-page-right">
                        <ReactStickyPolyfill>
                            <div className="paddle-doc-page-right-menu-wrap">
                                <div className="paddle-doc-page-right-menu-index">
                                    目录
                                </div>
                                <DocRightMenu
                                    {...docMenuHooks.right}
                                />
                            </div>
                        </ReactStickyPolyfill>
                    </div>
                    <div className="paddle-doc-page-center">
                        <ReactStickyPolyfill>
                            <div className="paddle-doc-page-left-menu-switch-wrap">
                                <div
                                    className="paddle-doc-page-left-menu-switch"
                                    onClick={toggleLeftMenuShow}
                                />
                            </div>
                        </ReactStickyPolyfill>
                        <DocArticle ref={docArticleRef} {...docArticleHook} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default InferenceDocument;