/**
 * @file 顶部导航栏搜索框
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';
import {AutoComplete, Input} from 'antd';

import headerSearchSug, {dropdownRender} from './HeaderSearchSug';
import {IHeaderSearchProps} from '../../type';


const HeaderSearch: React.FC<IHeaderSearchProps> = React.memo((props) => {
    const {
        searchKw,
        debounceGetSearchSug,
        onSearchKwChange,
        onSearchKwSelect,
        menuGetPopupContainer,
        submitSearch
    } = props;

    return (
        <div className="paddle-header-search-wrap">
            <AutoComplete
                className="paddle-header-search paddle-header-search-visible"
                dropdownClassName="paddle-header-search-drop"
                value={searchKw}
                onChange={onSearchKwChange}
                onSearch={debounceGetSearchSug}
                onSelect={onSearchKwSelect}
                defaultActiveFirstOption={false}
                dropdownMatchSelectWidth={false}
                dropdownRender={dropdownRender(props)}
                getPopupContainer={menuGetPopupContainer}
                dataSource={headerSearchSug(props) as any[]}
            >
                <Input
                    placeholder="PaddlePaddle"
                    className="paddle-header-search-input"
                    suffix={
                        <i
                            className="paddle-header-search-input-icon"
                            onClick={submitSearch}
                        />
                    }
                    onPressEnter={submitSearch}
                />
            </AutoComplete>
        </div>
    );
});

export default HeaderSearch;
