/**
 * @file 标记页面是否在加载状态
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useEffect, useState} from 'react';

const usePageLoading = (
    init?: boolean,
    defaultClassName: string = 'page-loading'
) => {
    const [pageLoading, setPageLoading] = useState<boolean>(!!init);
    const [defaultCN] = useState(defaultClassName);

    useEffect(() => {
        const body = document.getElementsByTagName('body')?.[0];
        if (body) {
            if (pageLoading) {
                const oldClass = body.className || '';
                body.className = `${oldClass} ${defaultCN}`;
            }
            else {
                const oldClass = body.className || '';
                const newClass = oldClass.split(' ')
                    .filter(k => k !== defaultCN);
                body.className = newClass.join(' ');
            }
        }
    }, [pageLoading, defaultCN]);

    const switchLoading = useCallback(() => {
        setPageLoading(old => !old);
    }, []);

    return {
        pageLoading,
        setPageLoading,
        switchLoading
    };
};

export default usePageLoading;
