import classNames from 'classnames';
import { useState } from 'react';
import { ILiteArc, IArcHover } from './../../type';
import './style.less';

interface IArcUnity extends ILiteArc, IArcHover {

};

const LiteArc: React.FC<IArcUnity> = props => {
    const {
        title,
        analyze,
        exeuate,
        mark,
        operateAccess,
        subAccess,
        handwareAccess,
        loadAndCalc,
        fusionPass
    } = props;

    const [currentNames, setCurrentNames] = useState<string>('');

    const handleMouseEnter = (name: string) => {
        setCurrentNames(name);
    };

    const handleMouseLeave = () => {
        setCurrentNames('');
    };

    return <div className='lite-page-arc'>
        <div className='lite-page-arc-title'>{ title }</div>

        <div className='lite-page-arc-analyze'>
            <div className='lite-page-arc-analyze-circle' />
            <div className='lite-page-arc-analyze-line' />
            <div className='lite-page-arc-analyze-title'>{ analyze.title }</div>


            <div className='lite-page-arc-analyze-step1'>
                <div className='lite-page-arc-analyze-step1-solid'>
                    <div className='lite-page-arc-analyze-step1-solid-title'>{ analyze.step1.title }</div>
                </div>
                <div className='lite-page-arc-analyze-step1-hover'>
                    <div className='lite-page-arc-analyze-step1-hover-right-1 lite-page-arc-arrow-right' />
                    <div className='lite-page-arc-analyze-step1-hover-right-2 lite-page-arc-arrow-right' />
                    <div className='lite-page-arc-analyze-step1-hover-content'>
                        {
                            loadAndCalc.map(item => <div className='lite-page-arc-analyze-step1-hover-content-card' key={item}>
                                { item }
                            </div>)
                        }
                    </div>
                </div>
            </div>


            <div className='lite-page-arc-analyze-up' />


            <div className='lite-page-arc-analyze-step2'>
                <div className='lite-page-arc-analyze-step2-solid'>
                    <div className='lite-page-arc-analyze-step2-solid-title'>{ analyze.step2.title }</div>
                    <div className='lite-page-arc-analyze-step2-solid-desc'>{ analyze.step2.desc }</div>
                </div>
                <div className='lite-page-arc-analyze-step2-hover'>
                    <div className='lite-page-arc-analyze-step2-hover-right-1 lite-page-arc-arrow-right' />
                    <div className='lite-page-arc-analyze-step2-hover-right-2 lite-page-arc-arrow-right' />
                    <div className='lite-page-arc-analyze-step2-hover-first'>
                        { fusionPass[0] }
                    </div>
                    <div className='lite-page-arc-analyze-step2-hover-center'>
                        {
                            fusionPass.slice(1, 7).map(item => <div className='lite-page-arc-analyze-step2-hover-center-card' key={item}>
                                { item }
                            </div>)
                        }
                    </div>
                    <div className='lite-page-arc-analyze-step2-hover-last'>
                        { fusionPass[7] }
                    </div>
                </div>
            </div>


        </div>



        <div className='lite-page-arc-exeuate'>
            <div className='lite-page-arc-exeuate-circle' />
            <div className='lite-page-arc-exeuate-line' />
            <div className='lite-page-arc-exeuate-title'>{ exeuate.title }</div>

            <div
                className='lite-page-arc-exeuate-step1'
                onMouseEnter={() => {handleMouseEnter('step1-hover-to-step4')}}
                onMouseLeave={handleMouseLeave}
            >
                <div className='lite-page-arc-exeuate-step1-solid'>
                    <div className='lite-page-arc-exeuate-step1-solid-title'>{ exeuate.step1.title }</div>
                    <div className='lite-page-arc-exeuate-step1-solid-desc'>{ exeuate.step1.desc }</div>
                </div>
                <div className='lite-page-arc-exeuate-step1-hover'>
                    {
                        operateAccess.map(item => <div className='lite-page-arc-exeuate-step1-hover-item' key={item}>
                            <div className='lite-page-arc-exeuate-step1-hover-item-word'>{ item }</div>
                        </div>)
                    }
                </div>
            </div>


            <div
                className='lite-page-arc-exeuate-step2-step3'
                onMouseEnter={() => {handleMouseEnter('step2-step3-hover-to-step4')}}
                onMouseLeave={handleMouseLeave}
            >

                <div className='lite-page-arc-exeuate-step2-step3-solid'>
                    <div className='lite-page-arc-exeuate-step2-step3-solid-title'>{ exeuate.step2.title }</div>
                    <div className='lite-page-arc-exeuate-step2-step3-solid-bottom'>
                        <div className='lite-page-arc-exeuate-step2-step3-solid-bottom-title'>{ exeuate.step3.title }</div>
                        <div className='lite-page-arc-exeuate-step2-step3-solid-bottom-desc'>{ exeuate.step3.desc }</div>
                    </div>
                </div>

                <div className='lite-page-arc-exeuate-step2-step3-hover'>
                    <div className='lite-page-arc-exeuate-step2-step3-hover-right lite-page-arc-arrow-right' />
                    <div className='lite-page-arc-exeuate-step2-step3-hover-top'>
                        {
                            subAccess.slice(0,2).map(item => <div className='lite-page-arc-exeuate-step2-step3-hover-top-item' key={item}>
                                <div className='lite-page-arc-exeuate-step2-step3-hover-top-item-word'>{ item }</div>
                            </div>)
                        }
                    </div>

                    <div className='lite-page-arc-exeuate-step2-step3-hover-middle'>
                        {
                            subAccess.slice(2,4).map(item => <div className='lite-page-arc-exeuate-step2-step3-hover-middle-item' key={item}>
                                { item }
                            </div>)
                        }
                    </div>

                    <div className='lite-page-arc-exeuate-step2-step3-hover-bottom'>
                        {
                            subAccess.slice(4).map(item => <div className='lite-page-arc-exeuate-step2-step3-hover-bottom-item' key={item}>
                                <div className='lite-page-arc-exeuate-step2-step3-hover-bottom-item-word'>{ item }</div>
                            </div>)
                        }
                    </div>
                </div>
            </div>


            <div className={classNames('lite-page-arc-exeuate-step4', `${currentNames}`)}>
                <div className='lite-page-arc-exeuate-step4-solid'>
                    <div className='lite-page-arc-exeuate-step4-solid-title'>{ exeuate.step4.title }</div>
                    <div className='lite-page-arc-exeuate-step4-solid-desc'>{ exeuate.step4.desc }</div>
                </div>

                <div className='lite-page-arc-exeuate-step4-hover'>
                    {
                        handwareAccess.map(item => <div className='lite-page-arc-exeuate-step4-hover-item' key={item}>
                            <div className='lite-page-arc-exeuate-step4-hover-item-word'>{ item }</div>
                        </div>)
                    }
                </div>
            </div>
            <div className='lite-page-arc-exeuate-mark'>
                {
                   mark.map(item => <div className='ite-page-arc-exeuate-mark-item' key={item}>
                       { item }
                   </div>)
                }
            </div>
        </div>
    </div>;
};


export default LiteArc;