/**
 * @file 文档页请求
 * @author FengGuang(fengguang01@baidu.com)
 */
import request from 'superagent';
import baseApiText from '../../api/baseApiText';
import {getSsrHostUrl} from '../../config/config';

export const fetchGetContext = (url: string): Promise<request.Response> => {
    return baseApiText(
        request.get(getSsrHostUrl(url))
            .send()
    );
};

