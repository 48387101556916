import React, { useEffect, useState } from 'react';

import { Form, Radio } from 'antd';
import usePadlleLiteDemo from './liteDemoHooks';
import { IpaddleLiteDemo } from './type';
import './style.less';


const LiteDemo: React.FC = () => {

    const { liteDemo } = usePadlleLiteDemo();
    const [currentList, setCurrentList] = useState<Pick<IpaddleLiteDemo, 'taskList'>['taskList']>([]);

    const handleFormChanges = (changedValues: {radioType?: string}, allValues: {radioType: string}) => {
        const tempList = liteDemo.taskList.filter(item => allValues.radioType === item.classify || allValues.radioType === 'all');
        setCurrentList(tempList);
    };

    useEffect(() => {
        setCurrentList(liteDemo.taskList);
    }, [liteDemo])

    return <div className="paddle-lite-demo-wrapper">
        <div className='paddle-lite-demo-title'>{ liteDemo.title }</div>
        <div className='paddle-lite-demo-desc'> {liteDemo.desc} </div>

        <Form
            layout="inline"
            className="paddle-lite-demo-type-wrap"
            onValuesChange={handleFormChanges}
            initialValues={{radioType: 'all'}}
        >
            <Form.Item label="类型" name="radioType">
                <Radio.Group buttonStyle="solid" size="small">
                    <Radio.Button value="all">全部</Radio.Button>
                        {
                            liteDemo.classifyList.map(item => <Radio.Button value={item} key={item}>
                                {item}
                            </Radio.Button>)
                        }
                    </Radio.Group>
            </Form.Item>
        </Form>
        
        <div className="paddle-lite-demo-card-wrapper">
            {
                currentList.map(item => <div className='paddle-lite-demo-card' key={item.title}>
                    <a href={item.targetLink} target='_blank' rel="noreferrer"><img className='paddle-lite-demo-card-img' src={item.titleImageUrl} alt='' /></a>
                    <div className='paddle-lite-demo-card-content'>
                        <a href={item.targetLink} target='_blank' rel="noreferrer">
                            <div className='paddle-lite-demo-card-title'>{ item.title }</div>
                        </a>
                        <div className='paddle-lite-demo-card-desc'>{ item.desc }</div>
                    </div>
                </div>)
            }
        </div>
    </div>
};

export default LiteDemo;