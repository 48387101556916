/**
 * @file 顶部导航栏
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useCallback, useMemo, useRef} from 'react';
import {
    // Avatar,
    Menu
} from 'antd';
import {
    RightOutlined
    // UserOutlined
} from '@ant-design/icons';
import classNames from 'classnames';

import HeaderSearch from './components/header-search/HeaderSearch';
import ReactStickyPolyfill from '../react-sticky-polyfill/ReactStickyPolyfill';
import headerData, {IMenuDataItem, menuRightData, findMenu} from './headerData';
import {isDocumentCanSearch} from './container';
import {IHeaderProps} from './type';
import A from '../a/A';

import logoImg from './image/logo.png';

import './style.less';

const MenuItemGroup = Menu.ItemGroup;
const MenuItem = Menu.Item;
const MenuSubMenu = Menu.SubMenu;
// const MenuDivider = Menu.Divider;


// 修改下拉弹窗的对齐位置，参考 // 参考 https://juejin.im/post/5c717a8d6fb9a049ab0e3e1d
// 由于 antd.Menu 没有声明 builtinPlacements，这里使用解构语句绕过 props 检查
const builtinPlacementsProps = {
    builtinPlacements: {
        bottomLeft: {
            points: ['tc', 'bc'],
            overflow: {
                adjustX: 1,
                adjustY: 1
            },
            offset: [0, 15]
        }
    }
};

function isSubMenuLarge(menuDataItem: IMenuDataItem): boolean {
    if (!menuDataItem.children || menuDataItem.children.length === 0) {
        return false;
    }
    if (menuDataItem.children.every(
        item => !item.children || item.children.length === 0
    )) {
        return false;
    }
    return true;
}

const Header: React.FC<IHeaderProps> = React.memo(props => {
    const {
        searchKw,
        searchSug,
        searchSugLoading,

        onSearchKwChange,
        debounceGetSearchSug,
        onSearchKwSelect,
        submitSearch
    } = props;

    const headerPopupWrapEl = useRef<HTMLDivElement>(null);

    const menuGetPopupContainer = useCallback(() => {
        return headerPopupWrapEl.current
            || (document.querySelector('.paddle-header-menu-popup') as HTMLElement)
            || document.body;
    }, []);

    // 靠左的菜单依据当前页面位置高亮
    const [menuSelectedKey, menuSelectedKeySet] = useMemo(() => {
        const selected = findMenu(
            headerData,
            window.location.href.slice(window.location.origin.length)
        );
        const keys = selected.map(item => item.key);
        const keysSet = new Set(keys);
        return [keys, keysSet];
    }, []);


    // 靠右的菜单依据当前页面位置高亮
    const linksMenuSelectedKey = useMemo(() => {
        const selected = findMenu(
            menuRightData,
            window.location.href.slice(window.location.origin.length)
        );
        return selected.map(item => item.key);
    }, []);


    return (
        <React.Fragment>
            <ReactStickyPolyfill>
                <div className="paddle-header-wrap">
                    <div className="paddle-header-background" />
                    <div className="paddle-header">
                        <div className="paddle-header-logo-item">
                            <A href="/" className="paddle-header-logo-wrap">
                                <img alt="logo" className="paddle-header-logo" src={logoImg} />
                            </A>
                        </div>

                        <div className="paddle-header-right">
                            {
                                isDocumentCanSearch
                                && <HeaderSearch
                                    searchKw={searchKw}
                                    searchSugLoading={searchSugLoading}
                                    searchSug={searchSug}
                                    onSearchKwChange={onSearchKwChange}
                                    debounceGetSearchSug={debounceGetSearchSug}
                                    onSearchKwSelect={onSearchKwSelect}
                                    menuGetPopupContainer={menuGetPopupContainer}
                                    submitSearch={submitSearch}
                                />
                            }
                            <div className="paddle-header-links">
                                <div className="paddle-header-links-menu">
                                    <Menu
                                        mode="horizontal"
                                        getPopupContainer={menuGetPopupContainer}
                                        selectedKeys={linksMenuSelectedKey}
                                    >
                                        {menuRightData.map(item => (
                                            <MenuItem key={item.key}>
                                                <A
                                                    className="paddle-header-links-link"
                                                    target={item.target}
                                                    href={item.url}
                                                    title={item.title}
                                                >
                                                    {item.title}
                                                </A>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            </div>
                        </div>
                        <div className="paddle-header-menu">
                            <Menu
                                mode="horizontal"
                                getPopupContainer={menuGetPopupContainer}
                                // 修改下拉弹窗的对齐位置，参考 // 参考 https://juejin.im/post/5c717a8d6fb9a049ab0e3e1d
                                // 由于 antd.Menu 没有声明 builtinPlacements，这里使用解构语句绕过 props 检查
                                {...builtinPlacementsProps}
                                selectedKeys={menuSelectedKey}
                            >
                                {headerData.map(item => {
                                    if (item.children && item.children.length > 0) {
                                        const submenuLarge = isSubMenuLarge(item);
                                        return (
                                            <MenuSubMenu
                                                popupClassName={classNames(
                                                    {'paddle-header-submenu-popup-large': submenuLarge}
                                                )}
                                                className={classNames(
                                                    {'ant-menu-item-selected': menuSelectedKeySet.has(item.key)}
                                                )}
                                                key={item.key}
                                                title={
                                                    <A
                                                        target={item.target}
                                                        href={item.url}
                                                    >
                                                        {item.title}
                                                    </A>
                                                }
                                            >
                                                {item.children.map(l2Item => {
                                                    if (!l2Item.children || l2Item.children.length === 0) {
                                                        return (
                                                            <MenuItem key={l2Item.key}>
                                                                <A
                                                                    target={l2Item.target}
                                                                    href={l2Item.url}
                                                                >
                                                                    {l2Item.title}
                                                                    {l2Item.tags && l2Item.tags.length > 0
                                                                    && (l2Item.tags.map((tag) => (
                                                                        <span
                                                                            key={tag.text}
                                                                            className="paddle-header-submenu-popup-large-tag">
                                                                        {tag.text}
                                                                    </span>
                                                                    )))}
                                                                </A>
                                                            </MenuItem>
                                                        );
                                                    }

                                                    if (!l2Item.title) {
                                                        return (
                                                            <MenuItemGroup
                                                                key={l2Item.key}
                                                                title={l2Item.title}
                                                                className="paddle-header-submenu-popup-group-no-title"
                                                            >
                                                                {l2Item.children.map(l3Item => (
                                                                    <Menu.Item key={l3Item.key}>
                                                                        <A
                                                                            target={l3Item.target}
                                                                            href={l3Item.url}
                                                                        >
                                                                            {l3Item.title}
                                                                            <RightOutlined
                                                                                className="paddle-header-submenu-popup-group-right-icon"
                                                                            />
                                                                        </A>
                                                                    </Menu.Item>
                                                                ))}
                                                            </MenuItemGroup>
                                                        );
                                                    }

                                                    return (
                                                        <MenuItemGroup
                                                            key={l2Item.key}
                                                            title={l2Item.title}
                                                        >
                                                            {l2Item.children.map(l3Item => (
                                                                <Menu.Item key={l3Item.key}>
                                                                    <A
                                                                        target={l3Item.target}
                                                                        href={l3Item.url}
                                                                    >
                                                                        {l3Item.title}
                                                                        {l3Item.tags && l3Item.tags.length > 0
                                                                        && (l3Item.tags.map((tag) => (
                                                                            <span
                                                                                key={tag.text}
                                                                                className={classNames(
                                                                                    'paddle-header-submenu-popup-large-tag',
                                                                                    tag.color
                                                                                )}
                                                                            >
                                                                            {tag.text}
                                                                        </span>
                                                                        )))}
                                                                    </A>
                                                                </Menu.Item>
                                                            ))}
                                                        </MenuItemGroup>
                                                    );
                                                })}
                                                {submenuLarge &&
                                                <div className="paddle-header-submenu-popup-large-bg" />}
                                            </MenuSubMenu>
                                        );
                                    }
                                    return (
                                        <MenuItem key={item.key}>
                                            {item.url
                                                ? <A
                                                    target={item.target}
                                                    href={item.url}
                                                >
                                                    {item.title}
                                                </A>
                                                : item.title
                                            }
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        </div>
                    </div>
                </div>
            </ReactStickyPolyfill>
            <ReactStickyPolyfill>
                <div ref={headerPopupWrapEl} className="paddle-header-menu-popup" />
            </ReactStickyPolyfill>
        </React.Fragment>
    );
});

export default Header;
