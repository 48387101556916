/**
 * @file 文档页页右侧锚点菜单
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useMemo} from 'react';
import classNames from 'classnames';
import {Menu} from 'antd';

import {IDocumentMenuItem} from '../../type';
import A from '../../../../../../components/a/A';


const renderSubMenu = (menuList: IDocumentMenuItem[], selectedSet: Set<string>) => (
    menuList.map(menuItem => {
        if (menuItem.children.length > 0) {
            return (
                <Menu.SubMenu
                    key={menuItem.key}
                    popupClassName={classNames({
                        'ant-menu-item-selected': selectedSet.has(menuItem.key)
                    })}
                    title={
                        <span
                            className={classNames({
                                'ant-menu-item-selected': selectedSet.has(menuItem.key)
                            })}
                        >
                            <A href={menuItem.link || ''}>
                                {menuItem.title}
                            </A>
                        </span>
                    }
                >
                    {renderSubMenu(menuItem.children, selectedSet)}
                </Menu.SubMenu>
            );
        }
        return (
            <Menu.Item key={menuItem.key}>
                <A href={menuItem.link || ''}>
                    {menuItem.title}
                </A>
            </Menu.Item>
        );
    })
);


export interface IDocRightMenuProps {
    openKeys: string[];
    selectedKeys: string[];
    menuList: IDocumentMenuItem[];
}

const DocRightMenu: React.FC<IDocRightMenuProps> = props => {
    const {
        openKeys,
        selectedKeys,
        menuList
    } = props;

    const selectedSet = useMemo(() => {
        return new Set(selectedKeys);
    }, [selectedKeys]);

    return (
        <Menu
            className="paddle-doc-page-right-menu"
            mode="inline"
            inlineIndent={14}
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            expandIcon={<span />}
        >
            {renderSubMenu(menuList, selectedSet)}
        </Menu>
    );
};

export default DocRightMenu;
