/**
 * @file 遍历菜单选项的父选项
 * @author FengGuang(fengguang01@baidu.com)
 */

import {IDocumentMenuItem} from '../../../type';

const getParentsKeyList = (
    theMenuList: IDocumentMenuItem[],
    key: string,
    menuListMapByChildKey: Map<string, IDocumentMenuItem>
) => {
    const result: string[] = [];
    let current = menuListMapByChildKey.get(key);
    while (current) {
        result.push(current.key);
        current = menuListMapByChildKey.get(current.key);
    }
    result.reverse();
    return result;
};

export default getParentsKeyList;
