import { ILiteJoin } from './../../type';
import './style.less';

const LiteJoin: React.FC<ILiteJoin> = props => {
    const {
        title,
        desc,
        list
    } = props;

    return <div className='lite-page-join'>
       <div className='lite-page-join-title'>{ title }</div>
       <div className='lite-page-join-desc'>{ desc }</div>
       <div className='lite-page-join-card-wrapper'>
           {
               list.map(item => <div className='lite-page-join-card' key={item.title} onClick={() => {window.open(item?.btnLink)}}>
                   <img className='lite-page-join-card-image' src={item.imageUrl} alt={item.title} />
                   <div className='lite-page-join-card-title'>{ item.title }</div>
                   <div className='lite-page-join-card-desc'>{ item.desc }</div>
               </div>)
           }
       </div>
    </div>;
};


export default LiteJoin;