import { ILiteBanner } from './../../type';
import './style.less';

const LiteBanner: React.FC<ILiteBanner> = props => {
    const {
        title,
        desc,
        btnWord1,
        btnLink1,
        btnWord2,
        btnLink3
    } = props;

    return <div className='lite-page-banner'>
        <div className='lite-page-banner-title'>{ title }</div>
        <div className='lite-page-banner-desc'>{ desc }</div>
        <div className='lite-page-banner-btn'>
            <a href={btnLink1} target='_blank' rel='noreferrer'>
                <div className='lite-page-banner-btn-item'>{ btnWord1 }</div>
            </a>
            <a href={btnLink3} target='_blank' rel='noreferrer'>
                <div className='lite-page-banner-btn-item'>{ btnWord2 }</div>
            </a>
        </div>
    </div>;
};


export default LiteBanner;