/**
 * @file 文档页页右侧锚点菜单
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useState} from 'react';
import {IDocumentMenuItem} from '../../type';


export const getRightMenuList = (
    menuList: IDocumentMenuItem[],
    selectedKeys?: string[]
): IDocumentMenuItem[] => {
    const keys = selectedKeys || [];
    if (keys.length === 0) {
        return [];
    }
    const keysSet = new Set(keys);

    let resultList: IDocumentMenuItem[] = [];
    menuList.forEach((item) => {
        item.children.forEach((subItem) => {
            if (keysSet.has(subItem.key)) {
                resultList = subItem.children;
            }
        });
    });
    return resultList;
};


const useDocRightMenu = () => {
    const [menuList, setMenuList] = useState<IDocumentMenuItem[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [openKeys, setOpenKeys] = useState<string[]>([]);

    return {
        menuList,
        selectedKeys,
        openKeys,

        setMenuList,
        setSelectedKeys,
        setOpenKeys
    };
};

export default useDocRightMenu;
