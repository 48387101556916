import React from 'react';
import classNames from 'classnames';
import ReactStickyPolyfill from '../../components/react-sticky-polyfill/ReactStickyPolyfill';
import DocArticle from './components/doc-article';
import DocLeftMenu from './components/doc-menu/components/doc-left-menu/DocLeftMenu';
import DocRightMenu from './components/doc-menu/components/doc-right-menu/DocRightMenu';
import useDocument from './documentHooks';
import { IDocumentMenuItem } from './type';

const LiteDoc: React.FC<{flag: string}> = props => {
    const {
        leftMenuShow,

        toggleLeftMenuShow,
        docArticleRef,
        docArticleHook,
        docMenuHooks
    } = useDocument();

    // const currentList = props.flag === 'api'
    //     ? docMenuHooks?.left?.menuList.slice(0, docMenuHooks.left.menuList.length - 1)
    //     : (docMenuHooks?.left?.menuList.slice(-1)[0]?.children ?? []);


    const transOrder = (guideMenu: IDocumentMenuItem[]) => {

        if (guideMenu.length === 7) {
            const transMenu:IDocumentMenuItem[] = [];
            transMenu.push(guideMenu[0]);
            transMenu.push(guideMenu[2]);
            transMenu.push(guideMenu[5]);
            transMenu.push(guideMenu[3]);
            transMenu.push(guideMenu[4]);
            transMenu.push(guideMenu[1]);
            transMenu.push(guideMenu[6]);
            return transMenu;
        }
        return [];
    };

    const currentList = (() => {
        const allList = docMenuHooks.left.menuList;
        if (allList[1]) {
            allList[1].children = allList[1].children.slice(0, 6);
        } 
        console.log('allList:', allList);
        const listObj: {
            apiDoc: IDocumentMenuItem[];
            faq: IDocumentMenuItem[];
            guide: IDocumentMenuItem[];
        } = { apiDoc: [], faq: [], guide: [] };

        const path = window.location.pathname;

        for (const item of allList) {
            if (path.includes('api') && item.title.includes('API')) {
                listObj.apiDoc.push(...item.children);
            }

            else if (path.includes('faq') && item.title.includes('FAQ')) {
                listObj.faq.push(item.children[0]);
            }

            else if(item.title !== 'API文档' && !item.title.includes('FAQ') && !item.title.includes('训练示例（预览') && !item.title.includes('训练推理示例说明')) {
                listObj.guide.push(item);
            }
        }
        
        if (path.includes('api')) {
            return listObj.apiDoc;
        }
        else if (path.includes('faq')) {
            return listObj.faq;
        }
        return transOrder(listObj.guide);
    })();

    console.log('currentList:', currentList);


    return <div className={
        classNames(
            'paddle-doc-page-container',
            {
                'paddle-doc-page-container-no-left':
                    docMenuHooks.left.menuList.length === 0,
                'paddle-doc-page-container-no-right':
                    docMenuHooks.right.menuList.length === 0
            }
        )}
    >
        <div
            className={classNames(
                'paddle-doc-page-main',
                {
                    'paddle-doc-page-left-menu-show': leftMenuShow
                }
            )}
        >
            <div className="paddle-doc-page-left-background" />
            <div className="paddle-doc-page-left">
                <ReactStickyPolyfill>
                    <div className="paddle-doc-page-left-menu-wrap">
                        <DocLeftMenu
                            {...{...docMenuHooks.left, menuList: currentList}}
                        />
                    </div>
                </ReactStickyPolyfill>
            </div>

            <div className="paddle-doc-page-right">
                <ReactStickyPolyfill>
                    <div className="paddle-doc-page-right-menu-wrap">
                        <div className="paddle-doc-page-right-menu-index">
                            目录
                        </div>
                        <DocRightMenu
                            {...docMenuHooks.right}
                        />
                    </div>
                </ReactStickyPolyfill>
            </div>

            <div className="paddle-doc-page-center">
                <ReactStickyPolyfill>
                    <div className="paddle-doc-page-left-menu-switch-wrap">
                        <div
                            className="paddle-doc-page-left-menu-switch"
                            onClick={toggleLeftMenuShow}
                        />
                    </div>
                </ReactStickyPolyfill>

                <DocArticle ref={docArticleRef} {...docArticleHook} />
            </div>
        </div>
    </div>
};

export default LiteDoc;