/**
 * @file 版本选择器
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useMemo, useState} from 'react';
import {SelectProps} from 'antd/es/select';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {useLatest} from 'react-use';

type OptionsType = Exclude<SelectProps<any>['options'], undefined>;

const useVersionSelect = () => {
    const routerMatch = useRouteMatch('/:version');
    const routerMatchRef = useLatest(routerMatch);
    const history = useHistory();
    const historyRef = useLatest(history);

    const [value, setValue] = useState<string>('');

    const [options, setOptions] = useState<OptionsType>([]);
    const optionValueSet = useMemo(() => {
        return new Set(options.map(v => v.value));
    }, [options]);
    const optionValueSetRef = useLatest(optionValueSet);


    const onChange = useCallback((keys: string | string[]) => {
        const theKey = Array.isArray(keys) ? keys[keys.length - 1] : keys;
        setValue(theKey);
        const tmp = (routerMatchRef.current?.params as any)?.version ?? '';
        const currentVersion = optionValueSetRef.current.has(tmp) ? tmp : '';
        const currentVersionPathStr = routerMatchRef.current?.url ?? '';
        const newVersion = theKey ? `/${theKey}` : '';
        const newURL = new URL(window.location.href);
        if (optionValueSetRef.current.has(currentVersion)) {
            newURL.pathname = newVersion + newURL.pathname.slice(currentVersionPathStr.length);
        }
        else {
            newURL.pathname = newVersion + newURL.pathname;
        }
        historyRef.current.push(newURL.href.slice(newURL.origin.length));
    }, [setValue, routerMatchRef, optionValueSetRef, historyRef]);

    const getCurrentVersion = useCallback((options: OptionsType) => {
        const optionsSet = new Set(options.map(i => i.value));
        const tmp = (routerMatchRef.current?.params as any)?.version ?? '';
        return optionsSet.has(tmp) ? tmp : '';
    }, [routerMatchRef]);

    return {
        value,
        setValue,

        options,
        setOptions,

        onChange,
        getCurrentVersion
    };
};

export default useVersionSelect;
