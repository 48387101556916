import { ILiteCooperate } from './../../type';
import './style.less';

const LiteCooperate: React.FC<ILiteCooperate> = props => {
    const {
        title,
        desc,
        list
    } = props;

    return <div className='lite-page-cooperate'>
        <div className='lite-page-cooperate-title'>{ title }</div>
        <div className='lite-page-cooperate-desc'>{ desc }</div>
        <div className='lite-page-cooperate-card-wrapper'>
            {
                list.map(item => <div className='lite-page-cooperate-card' key={item.imageUrl} onClick={() => {window.open(item?.btnLink)}}>
                    <img className='lite-page-cooperate-card-image' src={item.imageUrl} alt='' />
                </div>)
            }
        </div>
    </div>;
};


export default LiteCooperate;