import { ILiteProcess } from './../../type';
import './style.less';

const LiteProcess: React.FC<ILiteProcess> = props => {
    const {
        title,
        list
    } = props;

    return <div className='lite-page-process'>
        <div className='lite-page-process-title'>{ title }</div>
        <div className='lite-page-process-card-wrapper'>
            {
                list.map(item => <div className='lite-page-process-card' key={item.title}>
                    <div className='lite-page-process-card-arrow'>
                        <div className='lite-page-process-card-arrow-line' />
                        <div className='lite-page-process-card-arrow-tri' />
                    </div>
                    <img className='lite-page-process-card-image' src={item.imageUrl} alt='' />
                    <div className='lite-page-process-card-title'>{ item.title }</div>
                    <div className='lite-page-process-card-desc'>{ item.desc }</div>
                </div>)
            }
        </div>
    </div>;
};


export default LiteProcess;