/**
 * @file 文档页hooks
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useCallback, useEffect, useRef, useState} from 'react';
import {useLatest, useToggle} from 'react-use';
import {useHistory, useLocation} from 'react-router-dom';
import sleepPromise from 'sleep-promise';

import {fetchGetContext} from './documentApi';
import useDocArticle, {getDocArticleFromHTML} from './components/doc-article/useDocArticle';
import useDocMenu from './components/doc-menu/useDocMenu';
import getTitleFromDom from '../../utils/get-title-from-dom';
import getMenuItemFromDom from './components/doc-menu/components/getMenuItemFromDom';
import usePageLoading from '../../components/page-loading/usePageLoading';
import useAnchorScrollHighlight from './components/doc-menu/components/doc-right-menu/useAnchorScrollHighlight';
import useVersionSelect from './components/version-select/useVersionSelect';

import {
    isPaddleLite,
    isPaddleInference
} from '../../config/config';

const preLoad: any = {
    fetchGetContext: fetchGetContext(`${window.location.pathname}${window.location.search}`)
};

const useDocument = () => {
    const history = useHistory();
    const location = useLocation();

    const docArticleHook = useDocArticle();
    const docMenuHooks = useDocMenu(isPaddleInference);
    const versionSelectHook = useVersionSelect();
    const {setPageLoading} = usePageLoading(true);

    const [leftMenuCanShow] = useState<boolean>(true);

    const {setArticle} = docArticleHook;

    const {updateMenuList} = docMenuHooks;


    // 根据 hash 跳转到锚点位置
    const locationRef = useLatest(location);
    const scrollToAnchor = useCallback(() => {
        const anchorName = locationRef.current.hash.slice(1);
        if (!anchorName) {
            return;
        }
        const anchorDom = document.getElementById(anchorName)
            || document.querySelector(`a[name="${anchorName}"]`);
        if (anchorDom) {
            anchorDom.scrollIntoView({
                block: 'start'
            });
        }
    }, [locationRef]);

    // 根据url变化将浏览器滚动到顶部
    useEffect(() => {
        if (history.action === 'PUSH') {
            window.scrollTo({top: 0});
        }
    }, [history.action, docArticleHook.article]);

    useEffect(() => {
        scrollToAnchor();
    }, [location.hash, scrollToAnchor]);

    // 获取文档内容
    const theContextUrl = `${location.pathname}${location.search}`;

    useEffect(() => {
        (async () => {
            setPageLoading(true);
            const res = await (preLoad.fetchGetContext || fetchGetContext(theContextUrl))
                .catch(() => {
                    // ignore
                });
            delete preLoad.fetchGetContext;

            if (res) {
                const docDom = (() => {
                    if (typeof (res.text) === 'string') {
                        const frag = document.createElement('html');
                        frag.innerHTML = res.text;
                        return frag;
                    }
                    return res.text;
                })();

                document.title = getTitleFromDom(docDom);

                const menuParams = getMenuItemFromDom(docDom);
                const article = getDocArticleFromHTML(docDom) || '';

                setArticle(article);
                await sleepPromise(0);
                updateMenuList(menuParams.menuList, menuParams.selectedKeys);

                scrollToAnchor();
            }
            await sleepPromise(0);
            setPageLoading(false);
        })();
    }, [
        setPageLoading,
        scrollToAnchor,
        theContextUrl,
        setArticle,
        updateMenuList
    ]);

    const [leftMenuShow, toggleLeftMenuShow] = useToggle(true);

    const docRightMenuList = docMenuHooks.right.menuList;
    const setDocRightSelectedKeys = docMenuHooks.right.setSelectedKeys;
    const setDocRightOpenKeys = docMenuHooks.right.setOpenKeys;
    const docArticleRef = useRef<HTMLDivElement>(null);
    // 页面滚动时高亮对应的锚点
    useAnchorScrollHighlight(
        ({keys, parentKeys}) => {
            setDocRightSelectedKeys(keys);
            setDocRightOpenKeys([...parentKeys, ...keys]);
        },
        [docArticleRef, docRightMenuList]);


    // 获取版本选择器
    const setVersionOption = versionSelectHook.setOptions;
    const setVersionValue = versionSelectHook.setValue;
    const getCurrentVersion = versionSelectHook.getCurrentVersion;
    useEffect(() => {
        if (isPaddleLite) {
            const list = [
                {label: 'develop', value: 'develop'},
                {label: 'v2.10', value: 'v2.10'},
                {label: 'v2.9', value: 'v2.9'},
                {label: 'v2.8', value: 'v2.8'},
                {label: 'v2.7', value: 'v2.7'},
                {label: 'v2.6', value: 'v2.6'},
                {label: 'v2.3', value: 'v2.3'}
            ];
            setVersionValue(getCurrentVersion(list) || 'v2.10');
            setVersionOption(list);
        }
        if (isPaddleInference) {
            const list = [
                {label: 'master', value: 'master'},
                {label: 'v2.2', value: 'v2.2'},
                {label: 'v2.1', value: 'v2.1'},
                {label: 'v2.0', value: 'v2.0'},
                {label: 'v1.8', value: 'v1.8'}
            ];
            setVersionValue(getCurrentVersion(list) || 'v2.2');
            setVersionOption(list);
        }
    }, [theContextUrl]);

    return {
        leftMenuCanShow,
        leftMenuShow,

        toggleLeftMenuShow,

        docArticleRef,

        docMenuHooks,
        docArticleHook,
        versionSelectHook,

        isPaddleInference
    };
};

export default useDocument;
