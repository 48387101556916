/**
 * @file 文档页页右侧锚点菜单
 * @author FengGuang(fengguang01@baidu.com)
 */
import {useLayoutEffect, useState} from 'react';
import { useLocation } from 'react-use';
import {IDocumentMenuItem} from '../../type';

const getMenuItem = (
    menuList: IDocumentMenuItem[],
    deeplimit: number = -1,
    deep: number = 0
) => {
    if (deeplimit > -1 && deeplimit <= deep) {
        let resList: IDocumentMenuItem[] = [];
        menuList.forEach((item) => {
            if (!item.link || !/#.*/.test(item.link)) {
                resList.push({
                    ...item,
                    children: getMenuItem(item.children, deeplimit, deep + 1)
                });
            }
        });
        return resList;
    }

    let resList: IDocumentMenuItem[] = [];
    menuList.forEach((item) => {
        resList.push({
            ...item,
            children: getMenuItem(item.children, deeplimit, deep + 1)
        });
    });
    return resList;
};

export const getLeftMenuList = (
    menuList: IDocumentMenuItem[],
    selectedKeys?: string[],
    isPaddleInference?: boolean
) => {
    if (!selectedKeys || !selectedKeys.length) {
        return getMenuItem(menuList, 2);
    }
    const keys = selectedKeys || [];
    if (keys.length === 0) {
        return [];
    }

    const keysSet = new Set(keys);
    const selectedMenu = menuList.find((item) => {
        return keysSet.has(item.key);
    });
    // return selectedMenu ? getMenuItem(selectedMenu.children, 1) : [];
    if (!isPaddleInference) {
        return selectedMenu ? getMenuItem(menuList, 2) : [];
    }
    else {
        return selectedMenu ? getMenuItem(selectedMenu.children, 1) : [];
    }
};

const useDocLeftMenu = () => {
    const [menuList, setMenuList] = useState<IDocumentMenuItem[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [openKeys, setOpenKeys] = useState<string[]>([]);

    const location = useLocation();
    const locationPathname = location.pathname ?? '';

    useLayoutEffect(() => {
        const pathKey = menuList.find(i => i.link === locationPathname)?.key ?? '';
        setOpenKeys((oldKeys) => {
            return oldKeys.indexOf(pathKey) > -1 ? oldKeys : [...oldKeys, pathKey];
        });
    }, [menuList, locationPathname, setOpenKeys]);

    return {
        menuList,
        selectedKeys,
        openKeys,

        setMenuList,
        setSelectedKeys,
        setOpenKeys
    };
};

export default useDocLeftMenu;
