/**
 * @file 顶部导航栏
 * @author FengGuang(fengguang01@baidu.com)
 */

import {useCallback, useMemo, useRef, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {useLatest} from 'react-use';
import debounce from 'lodash/debounce';
import omit from 'object.omit';

import {
    fetchGetHeaderSearchSug
} from './headerApi';

import {ISearchSugItem, ISearcuSugResItem} from './type';
import filteHtml from '../../utils/filte-html';
import {getHostUrl} from '../../config/config';


const useHeaderHooks = (props?: RouteComponentProps) => {
    const routePropsRef = useRef<RouteComponentProps | undefined>(props);
    routePropsRef.current = props;

    const [searchKw, setSearchKw] = useState<string>(() => {
        if (typeof (window) !== 'object' || !window.location) {
            return '';
        }
        if (window.location.pathname === '/searchdoc') {
            const searchParam = new URLSearchParams(window.location.search.slice(1));
            return searchParam.get('q') || '';
        }
        return '';
    });
    const searchKwRef = useLatest(searchKw);
    const [searchSugLoading, setSearchSugLoading] = useState<boolean>(false);
    const [searchSug, setSearchSug] = useState<ISearchSugItem[]>([]);
    const searchSugRef = useLatest(searchSug);

    const debounceGetSearchSug = useMemo(() => {
        const getSearchSug = async (kw: string) => {
            setSearchSugLoading(true);
            const docInfo = (() => {
                const docInfo: { [key: string]: string | undefined } = {};
                if (window.location.pathname === '/searchdoc') {
                    const searchParam = new URLSearchParams(window.location.search.slice(1));
                    docInfo.language = searchParam.get('language') || '';
                    docInfo.version = searchParam.get('version') || '';
                }
                return omit(docInfo, val => typeof (val) !== 'undefined');
            })();
            const res = await fetchGetHeaderSearchSug(kw, docInfo)
                .catch(() => {
                    // ignore
                });
            setSearchSugLoading(false);
            if (res) {
                const data: ISearcuSugResItem[] = res.body.data || [];

                const list: ISearchSugItem[] = data.map((item) => {
                    const newItem: ISearchSugItem = {
                        key: item.title + item.path,
                        title: item.title,
                        link: item.path,
                        type: ''
                    };
                    if (item.path.indexOf('/api/') > -1 || item.path.indexOf('/api_cn/') > -1) {
                        newItem.type = 'Api';
                    }
                    else if (item.path.indexOf('/models/') > -1) {
                        newItem.type = 'Models';
                    }
                    else {
                        newItem.type = 'Doc';
                    }
                    return newItem;
                });
                setSearchSug(list);
            }
        };
        const debounceGetSearchSug = debounce(getSearchSug, 200);
        return (kw: string) => {
            setSearchSugLoading(true);
            return debounceGetSearchSug(kw);
        };
    }, []);

    const onSearchKwSelect = useCallback((value: string): void => {
        const sugIndex = searchSugRef.current.findIndex(i => i.key === value);
        const sugItem = searchSugRef.current[sugIndex];
        if (sugItem) {
            setSearchKw(sugItem.title);
        }
        if ((window as any)._hmt) {
            const docInfo = {
                lang: 'zh', // emun: zh/en
                version: '',
                ...(window as any).docInfo
            };
            const theTitle = filteHtml(sugItem?.title ?? '');
            (window as any)._hmt.push([
                '_trackEvent',
                '文档搜索',
                '点击sug',
                `标题"${theTitle}",搜索词"${searchKwRef.current}",语言"${docInfo.lang}",版本"${docInfo.version}"`
            ]);
            const top5Str = sugIndex < 5 ? 'Top5' : '非Top5';
            (window as any)._hmt.push([
                '_trackEvent',
                '文档搜索',
                '点击sug Top5',
                `${top5Str},第${sugIndex + 1}条,标题"${theTitle}",搜索词"${searchKwRef.current}",语言"${docInfo.lang}",版本"${docInfo.version}"`
            ]);
        }
        setTimeout(() => {
            window.location.href = getHostUrl(sugItem?.link);
        }, 0);
    }, [searchKwRef, searchSugRef, setSearchKw]);

    const submitSearch = useCallback(() => {
        // 在 document 页面中从全局接收 文档参数
        const docInfo: any = {
            lang: 'zh',
            ...(window as any).docInfo
        };

        const theSearchKw = searchKwRef.current || 'PaddlePaddle';

        const searchParams = omit({
            q: theSearchKw,
            language: docInfo.lang,
            version: docInfo.version
        }, val => typeof (val) !== 'undefined');

        if (routePropsRef.current && routePropsRef.current.location.pathname === '/searchdoc') {
            // 如果当前是搜索页，从 url 中接收 language 和 version 参数
            const thisPageQuery = new URLSearchParams(routePropsRef.current.location.search.slice(1));
            const thisPageSearchParams = omit({
                language: thisPageQuery.get('language') || undefined,
                version: thisPageQuery.get('version') || undefined
            }, val => typeof (val) !== 'undefined');
            const thisSearchParams = {
                ...searchParams,
                ...thisPageSearchParams
            };
            const query = new URLSearchParams(thisSearchParams);
            window.location.href = getHostUrl(`/searchdoc?${query.toString()}`);
            window.scrollTo({top: 0});
        }
        else {
            const query = new URLSearchParams(searchParams);
            window.location.href = getHostUrl(`/searchdoc?${query.toString()}`);
        }
    }, [searchKwRef]);


    return {
        searchKw,
        searchSug,
        searchSugLoading,

        onSearchKwChange: setSearchKw,
        debounceGetSearchSug,
        onSearchKwSelect,
        submitSearch
    };
};

export default useHeaderHooks;
