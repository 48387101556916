/**
 * @file 文档页页左侧菜单
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useCallback} from 'react';
import {Menu, Tooltip} from 'antd';
import {MenuProps} from 'antd/es/menu';
import {ExclamationCircleFilled} from '@ant-design/icons';

import LinkA from '../../../../../../components/a/LinkA';
import {IDocumentMenuItem} from '../../type';


const renderSubMenu = (menuList: IDocumentMenuItem[]) => (
    menuList.map(menuItem => {
        const isDataset = menuItem.title === 'paddle.dataset';
        const isFluid = menuItem.title === 'paddle.fluid';
        if (menuItem.children.length > 0) {
            return (
                <Menu.SubMenu
                    key={menuItem.key}
                    icon={<i className="paddle-doc-page-left-menu-arrow" />}
                    title={
                        <LinkA to={menuItem.link || ''}>
                            {menuItem.title}
                            {isDataset
                            && (
                                <Tooltip
                                    placement="bottom"
                                    title="paddle.dataset 将在未来废弃，请使用paddle.version.dataset与paddle.text.datasets。"
                                >
                                    <ExclamationCircleFilled
                                        style={{marginLeft: 5}}
                                    />
                                </Tooltip>
                            )}
                            {isFluid
                            && (
                                <Tooltip
                                    placement="bottom"
                                    title="paddle.fluid 将在未来废弃，请使用其他可替代的API。"
                                >
                                    <ExclamationCircleFilled
                                        style={{marginLeft: 5}}
                                    />
                                </Tooltip>
                            )}
                        </LinkA>
                    }
                >
                    {renderSubMenu(menuItem.children)}
                </Menu.SubMenu>
            );
        }
        return (
            <Menu.Item key={menuItem.key}>
                <LinkA to={menuItem.link || ''}>
                    {menuItem.title}
                </LinkA>
            </Menu.Item>
        );
    })
);


export interface IDocLeftMenuProps {
    openKeys: string[];
    selectedKeys: string[];
    menuList: IDocumentMenuItem[];

    setOpenKeys: (openKeys: string[]) => void;
}

const DocLeftMenu: React.FC<IDocLeftMenuProps> = props => {
    const {
        openKeys,
        selectedKeys,
        menuList,
        setOpenKeys
    } = props;

    const onOpenChange = useCallback<Exclude<MenuProps['onOpenChange'], undefined>>(
        (keys) => {
            setOpenKeys(keys.map(k => '' + k));
        },
        [setOpenKeys]
    );

    return (
        <Menu
            className="paddle-doc-page-left-menu"
            mode="inline"
            selectedKeys={selectedKeys}
            inlineIndent={16}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            expandIcon={<React.Fragment />}
        >
            {renderSubMenu(menuList)}
        </Menu>
    );
};

export default DocLeftMenu;
